import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "react-bootstrap";

const Pagination = ({ currentPage, setCurrentPage, totalItems, itemsPerPage }) => {

  const totalPages = Math.ceil(totalItems / itemsPerPage) - 1

  return (
    <div className="pagination d-flex justify-content-center mt-4 px-3">
      <Button
        className="pag-prev p-3 bg-light border-0"
        disabled={currentPage === 0}
        onClick={() => {
          setCurrentPage(--currentPage)
          window.scrollTo(0, 0);
        }}

      >
        <StaticImage
          src='../images/shapes/leftArrow.svg'
          alt="previous arrow"
        />
      </Button>
      <Button
        className="pag-next p-3 bg-light border-0"
        disabled={currentPage === totalPages}
        onClick={() => {
          setCurrentPage(++currentPage)
          window.scrollTo(0, 0);
        }}
      >
        <StaticImage
          src='../images/shapes/Group 2.svg'
          alt="next arrow"
        />
      </Button>
    </div>
  )
}

export default Pagination